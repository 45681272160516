// SidebarData.js
import { Settings } from "@mui/icons-material";
import React, { useEffect } from "react";
import { BiCalendarEdit } from "react-icons/bi";
import { FaUserCircle, FaUsers } from "react-icons/fa";
import {
  GrMapLocation,
  GrOverview,
  GrUserSettings,
  GrValidate,
} from "react-icons/gr";
import { HiHome } from "react-icons/hi2";
import { MdDashboard, MdLockReset, MdOutlineMenuBook } from "react-icons/md";
import { PiPasswordBold } from "react-icons/pi";
import * as RiIcons from "react-icons/ri";
import { TbMessageCircle } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../app/slices/authSlice2";
import { getUser } from "../getUser";

export const SidebarFunction = () => {
  const dispatch = useDispatch();
  let user = useSelector((state) => state.Auth.user);
  useEffect(() => {
    async function getUserAndSetInStore() {
      if (!user) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        user = await getUser();
        if (user) {
          dispatch(setUser(user));
        }
      }
    }
    if (!user) {
      getUserAndSetInStore();
    }
  }, [dispatch, user]);
  return user;
};

// Sidebar data with LogoutButton component
export const SidebarData = [
  {
    title: "Home",
    path: "/",
    icon: <HiHome />,
    access: ["public", "user", "coordinator", "admin"],
  },

  // Admin-only routes
  {
    title: "Dashboard",
    path: "/admin",
    icon: <MdDashboard />,
    access: ["admin"],
  },
  {
    title: "Menu",
    path: "/admin/menu_master",
    icon: <MdOutlineMenuBook />,
    access: ["admin"],
  },
  {
    title: "Overview",
    icon: <GrOverview />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    access: ["admin"],
    subNav: [
      {
        title: "Area",
        path: "/admin/area_master",
        icon: <GrMapLocation />,
        access: ["admin"],
      },
      {
        title: "Managers",
        path: "/admin/manager",
        icon: <GrUserSettings />,
        access: ["admin"],
      },
      {
        title: "Coordinator",
        path: "/admin/coordinator_master",
        icon: <GrUserSettings />,
        access: ["admin"],
      },
      {
        title: "Members",
        path: "/admin/member_master",
        icon: <FaUsers />,
        access: ["admin"],
      },
    ],
  },
  {
    title: "Settings",
    icon: <Settings />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    access: ["admin"],
    subNav: [
      {
        title: "Manage Dates",
        path: "/admin/search-member-dates",
        icon: <BiCalendarEdit />,
        access: ["admin"],
      },
      {
        title: "Create Dates",
        path: "/admin/create-member-dates",
        icon: <GrValidate />,
        access: ["admin"],
      },
      {
        title: "Restore Member Password",
        path: "/admin/forgot-password",
        icon: <MdLockReset />,
        access: ["admin"],
      },
      {
        title: "Reset Password",
        path: "/admin/reset-password",
        icon: <PiPasswordBold />,
        access: ["admin"],
      },
    ],
  },
  {
    title: "Profile",
    path: "/admin/profile",
    icon: <FaUserCircle />,
    access: ["admin"],
  },

  // Coordinator-only routes
  {
    title: "Dashboard",
    path: "/coordinator",
    icon: <MdDashboard />,
    access: ["coordinator"],
  },
  {
    title: "Members",
    path: "/coordinator/member",
    icon: <FaUsers />,
    access: ["coordinator"],
  },
  {
    title: "Settings",
    icon: <Settings />,
    iconClosed: <RiIcons.RiArrowDownSFill />,
    iconOpened: <RiIcons.RiArrowUpSFill />,
    access: ["coordinator"],
    subNav: [
      {
        title: "Restore Member Password",
        path: "/coordinator/forgot-password",
        icon: <MdLockReset />,
        access: ["coordinator"],
      },
      {
        title: "Reset Password",
        path: "/coordinator/reset-password",
        icon: <PiPasswordBold />,
        access: ["coordinator"],
      },
    ],
  },
  {
    title: "Profile",
    path: "/coordinator/profile",
    icon: <FaUserCircle />,
    access: ["coordinator"],
  },

  // Member-only routes
  {
    title: "Dashboard",
    path: "/member",
    icon: <MdDashboard />,
    access: ["user"],
  },
  {
    title: "Default Days",
    path: "/member/days",
    icon: <FaUsers />,
    access: ["user"],
  },
  {
    title: "Profile",
    path: "/member/profile",
    icon: <FaUserCircle />,
    access: ["user"],
  },
  {
    title: "Reset Password",
    path: "/member/reset-password",
    icon: <PiPasswordBold />,
    access: ["user"],
  },

  // Public routes
  {
    title: "Contact Us",
    path: "/contact-us",
    icon: <TbMessageCircle />,
    access: ["public", "user", "coordinator", "admin"],
  },
];
