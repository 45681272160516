// SubMenu.jsx
import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const SidebarLink = styled(Link)`
  display: flex;
  color: #e1e9fc;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  list-style: none;
  height: 60px;
  text-decoration: none;
  font-size: 18px;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  transition-duration: 200ms;

  &:hover {
    background-color: rgb(25 118 210 / 0.5);
    // background: #252831;
    // background: #787e90;
    // opacity: 0.85;
    // border-left: 4px solid #632ce4;
    border-left: 4px solid var(--mehndiGolden);
    cursor: pointer;
    padding: 12px 16px 12px 12px;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 12px;
`;

const DropdownLink = styled(Link)`
// background: #414757;
  margin: 3px 10px;
  background: #787e90;
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  color: #f5f5f5;
  border-bottom: 2px solid var(--mehndiGolden);
  border-radius: 4px;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  transition: all 0.2s ease-in-out;
  padding-left: 1rem;

  &:hover {
    // background: #632ce4;
    background: var(--mehndiGolden);
    cursor: pointer;
    color: black;
  }
`;

const DropDownIcon = styled.span`
  font-size: 20px;
`

const SubMenu = ({ item, showSidebar, userLoggedIn }) => {
  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => setSubnav(!subnav);

  // Check if the user's role has access to the item
  const isAuthorized = (item) =>
    item.access.includes(userLoggedIn ? userLoggedIn.role : "public");

  return (
    <>
      {isAuthorized(item) && (
        <SidebarLink
          to={item.path}
          onClick={() => {
            if (item.subNav) showSubnav();
            if (item.path) showSidebar();
          }} >
          <div className="flex items-center w-full">
            <span className="inline-flex items-center justify-center w-8">
              {item.icon}
            </span>

            <SidebarLabel>{item.title}</SidebarLabel>
          </div>
          <div>{item.subNav && (subnav ? item.iconOpened : item.iconClosed)}</div>
        </SidebarLink>
      )}

      {subnav &&
        item.subNav &&
        item.subNav.map((subItem, index) =>
          isAuthorized(subItem) ? (
            <DropdownLink to={subItem.path} key={index}
              onClick={() => {
                if (item.subNav) showSubnav();
                showSidebar();
              }} >
              <DropDownIcon>{subItem.icon}</DropDownIcon>
              <SidebarLabel className="text-sm">{subItem.title}</SidebarLabel>
            </DropdownLink>
          ) : null
        )}
    </>
  );
};

export default SubMenu;