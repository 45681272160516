import { memo } from "react";
import { Navigate, useOutletContext } from "react-router-dom";

const RestrictedRoute = ({ path, Component }) => {
    const { checkRouteAccess } = useOutletContext();

    if (!checkRouteAccess(path)) {
        return <Navigate to="/" replace />;
    }

    return <Component />;
};

export default memo(RestrictedRoute);